




import { VueConstructor } from 'vue'
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { StructureConfigurable } from '../../../../support/mixins'

import { Action } from './CartItem.contracts'
import {
  CART_ITEM_COMPONENT_CONFIG_MAP,
  CART_ITEM_COMPONENT_KEY, CartItemComponentConfig, cartItemComponentRegistry, CartItemLayout,
  CartItemSizeMap
} from './CartItem.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CartItem>({
  name: 'CartItem',
  created () {
    this.config = this.getComponentConfig(CART_ITEM_COMPONENT_KEY, { ...CART_ITEM_COMPONENT_CONFIG_MAP })
  }
})
export class CartItem extends Mixins(Vue, StructureConfigurable) {
  @Prop({ required: false, type: Array })
  public actions?: Action[]

  @Prop({ type: String, required: false })
  public readonly categoryUrl?: string

  @Prop({ required: true, type: String })
  public description!: string

  @Prop({ required: false, type: String })
  public enhancedDesc?: string

  @Prop({ required: false, type: Boolean, default: false })
  public isDisabled!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public isGift!: boolean

  @Prop({ required: true, type: Number })
  public price!: number

  @Prop({ required: false, type: Number })
  public promotionPrice: number | undefined

  @Prop({ required: true, type: Array })
  public properties!: string[]

  @Prop({ required: false, type: Number })
  public quantity?: number

  @Prop({ required: true, type: Object })
  public thumbnail!: ImageProps

  @Prop({ required: true, type: String })
  public title!: string

  @Prop({ required: false, type: Boolean, default: true })
  public shouldDisableQuantity?: boolean

  @Prop({ required: false, type: String, default: CartItemSizeMap.Medium })
  public size!: string

  @Prop({ required: false, type: Function })
  public quantityCallback!: (change: number) => Promise<boolean> | boolean

  @Prop({ type: String, required: false })
  public readonly urlPath?: string

  protected config!: CartItemComponentConfig

  public get cartItemComponent (): VueConstructor {
    return cartItemComponentRegistry[this.getConfigProperty<string>('layout')]
  }
}

export default CartItem
